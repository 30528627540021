import * as React from "react";
import "./suggestions.css";

const moment = require("moment-timezone");

export const Suggestions = ({ suggestions, isViewingHistory, timezone }) => {
  if (isViewingHistory) {
    return (
      <div className="suggestions-text-div">
        {Object.entries(suggestions)
          .reverse()
          .map(([dueDate, vals]) => {
            if (
              moment.tz(dueDate, timezone).format("YYYY-MM-DD") <
              moment.tz(timezone).format("YYYY-MM-DD")
            ) {
              return (
                <div className="suggestions-week-text-div">
                  <h4>
                    WEEK ENDING {moment.tz(dueDate, timezone).format("MMM Do")}
                  </h4>
                  <p>
                    <ul>
                      {vals.map((el) => (
                        <li>{el}</li>
                      ))}
                    </ul>
                  </p>
                </div>
              );
            }
          })}
      </div>
    );
  } else if (!isViewingHistory && Object.entries(suggestions).length > 0) {
    // Look for current recommendations.
    for (const [dueDate, vals] of Object.entries(suggestions)) {
      if (
        moment.tz(dueDate, timezone).format("YYYY-MM-DD") >=
        moment.tz(timezone).format("YYYY-MM-DD")
      ) {
        return (
          <div className="suggestions-text-div">
            <div className="suggestions-week-text-div">
              <h4>
                WEEK ENDING{" "}
                {moment.tz(dueDate, "YYYY-MM-DD", timezone).format("MMM Do")}
              </h4>
              <p>
                <ul>
                  {vals.map((el) => (
                    <li>{el}</li>
                  ))}
                </ul>
              </p>
            </div>
          </div>
        );
      }
    }
    return <p>No suggestions to display.</p>;
  } else {
    return <p>No suggestions to display.</p>;
  }
};
