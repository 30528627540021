import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DashboardNav from "../../../components/navbar";
import Navbar from "react-bootstrap/Navbar";
import {
  RealmAppProvider,
  useRealmApp,
  OnboardingRedirector,
  APP_ID,
} from "../../../components/realm_app";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { Container } from "react-bootstrap";
import update from "immutability-helper";
import {
  DAYS_IN_WEEK,
  getNextMeetingTime,
  getRelationTitle,
  MILLISECONDS_PER_WEEK,
  getCircleStartTime,
} from "../../../utils";
import { Footer } from "../../../components/footer";
import { Suggestions } from "../../../components/suggestions";
import "./goals.css";
import moment from "moment";

export const statusOptions = [
  {
    value: 5,
    label: "Complete",
  },
  {
    value: 3,
    label: "Made Progress",
  },
  {
    value: 1,
    label: "Incomplete",
  },
  {
    value: -1,
    label: "",
  },
];

export const tableStatusFormatter = (cell) => {
  const empty_pill = <div className={"empty-pill status-pill"}>&nbsp;</div>;

  if (cell == -1) {
    return empty_pill;
  }

  if (cell) {
    const statusLabel = statusOptions.find((x) => x.value == cell)?.label || "";
    const className = statusLabel.replace(" ", "").toLowerCase();
    return <div className={className + "-pill status-pill"}>{statusLabel}</div>;
  } else {
    return empty_pill;
  }
};

export const tableGoalFormatter = (cell) => {
  const pill = <div className={"goal-pill"}>{cell}&nbsp;</div>;
  return pill;
};

const MyGoalsPage = () => {
  const realmApp = useRealmApp();
  const url = new URL(location.href);

  const [stateReady, setStateReady] = useState(false);

  const myUserId = realmApp.appCustomData?._id;
  const [menteeName, setMenteeName] = useState(
    getRelationTitle("mentee", true)
  );
  const [menteeUserId, setMenteeUserId] = useState(null);

  const [circleKickoffTime, setCircleKickoffTime] = useState(null);
  const [goals2Data, setGoals2Data] = useState({});
  const [suggestions, setSuggestions] = useState({});
  // all acceptable timezone strings:
  // https://gist.github.com/diogocapela/12c6617fc87607d11fd62d2a4f42b02a
  const [timezone, setTimezone] = useState("America/Los_Angeles");

  const [nextMtgTime, setNextMtgTime] = useState(null);
  const [nextWeekEndTime, setNextWeekEndTime] = useState(null);
  const [viewingIdx, setViewingIdx] = useState(-1);
  const [viewingWhom, setViewingWhom] = useState(null);
  const [viewingOldSuggestions, setViewingOldSuggestions] = useState(false);

  const [editRowIdx, setEditRowIdx] = useState(0);

  function getGoalTimestampFromIdx(idx) {
    // JavaScript has pretty good index edge case handling.
    // E.g.
    // at(-1) returns last element
    // at(OUT_OF_BOUNDS_INT) returns undefined
    // at(ANY_INT) on an empty returns undefined
    // TypeScript does not inherently support .at() so we use [] for indexing.
    // This function may return undefined!
    // undefined is considered "falsey" in JavaScript.
    return parseInt(Object.keys(goals2Data).sort()[idx]);
  }

  function getCircleWeekNumber(week_end_timestamp) {
    if (circleKickoffTime == null) {
      return null;
    }

    let weekEndTime = circleKickoffTime;
    let weekNumber = 0;

    while (week_end_timestamp > weekEndTime) {
      weekEndTime += 1000 * 60 * 60 * 24 * DAYS_IN_WEEK;
      weekNumber += 1;
    }

    return weekNumber == 0 ? null : weekNumber;
  }

  async function fetchFromMongoAndSetState(userIdFromUseEffect?) {
    // Sets Goals Data
    // Initializes index for accessing Goals Data to latest date
    // Sets Next Meeting Time
    setStateReady(false);

    let userId =
      url.searchParams.get("view") === "theirs" ? menteeUserId : myUserId;
    if (userId == null) {
      userId = userIdFromUseEffect;
    }
    setViewingWhom(userId);

    setGoals2Data({});
    setNextMtgTime(null);
    setNextWeekEndTime(null);
    setViewingIdx(-1);
    let newData = {};
    let newViewingIdx = -1;

    if (!userId) {
      setStateReady(true);
      return;
    }

    const circleDoc = await realmApp.fetchCircle();
    if (
      circleDoc &&
      circleDoc.suggestions &&
      Object.keys(circleDoc.suggestions).length > 0
    ) {
      setSuggestions(circleDoc.suggestions);
    }

    if (circleDoc && circleDoc.timezone) {
      setTimezone(circleDoc.timezone);
    }

    const docWithGoals2 = await realmApp.appCurrentUser?.functions.getSingleUserAttribute(
      userId,
      "goals2"
    );
    if (
      docWithGoals2 &&
      docWithGoals2.goals2 &&
      Object.keys(docWithGoals2.goals2).length > 0
    ) {
      newData = docWithGoals2.goals2;
      const dates = Object.keys(newData);
      // Find the latest week recorded
      newViewingIdx = dates.length - 1;
    }

    let newNextMtgTime = null;
    const scheduleHolder =
      userId === myUserId ? realmApp.appCustomData?.mentor : myUserId;
    if (scheduleHolder) {
      const docWithSchedule = await realmApp.appCurrentUser?.functions.getCheckInSchedule(
        scheduleHolder
      );
      if (
        docWithSchedule &&
        docWithSchedule.menteeMeetingTime &&
        docWithSchedule.menteeMeetingTime.first_timestamp
      ) {
        newNextMtgTime = getNextMeetingTime(
          DAYS_IN_WEEK,
          docWithSchedule.menteeMeetingTime.first_timestamp
        );
      } else {
        console.log(
          `Unable to find a meeting time. ${getRelationTitle(
            "mentor",
            true
          )} needs to set up a meeting.`
        );
      }
    }

    let fetchedCircleKickoffTime = getCircleStartTime(circleDoc);
    if (fetchedCircleKickoffTime) {
      setCircleKickoffTime(fetchedCircleKickoffTime);

      // first week ends 7 days after circle kickoff time
      let newNextWeekEndTime = (fetchedCircleKickoffTime += MILLISECONDS_PER_WEEK);

      let now = new Date().getTime();
      while (now > newNextWeekEndTime) {
        newNextWeekEndTime += MILLISECONDS_PER_WEEK;
      }
      setNextWeekEndTime(newNextWeekEndTime);

      console.log(
        `this week: ${newNextWeekEndTime}, ends on ${new Date(
          newNextWeekEndTime
        )}`
      );
    } else {
      setNextWeekEndTime(newNextMtgTime);
    }

    setNextMtgTime(newNextMtgTime);
    setViewingIdx(newViewingIdx);
    setGoals2Data(newData);
    setStateReady(true);
  }

  useEffect(() => {
    async function prepare() {
      await realmApp
        .fetchMentorMentee({ name: 1, phone: 1, username: 1 })
        .then(async function process(result) {
          if (result.get("mentee")) {
            setMenteeName(result.get("mentee")["username"].split(" ")[0]);
            const menteeId = result.get("mentee")["_id"];
            setMenteeUserId(menteeId);
            const viewingWhomLocal =
              url.searchParams.get("view") === "theirs" ? menteeId : myUserId;
            setViewingWhom(viewingWhomLocal);
            // Only pass in an ID to fetchFromMongoAndSetState on first page
            // load when directly navigating to view=theirs because
            // React setState functions are asynchronous. That means
            // setMenteeUserId may not complete in time
            // for fetchFromMongoAndSetState to use.
            await fetchFromMongoAndSetState(viewingWhomLocal);
          } else {
            console.log("User has not been assigned a mentee yet.");
            fetchFromMongoAndSetState(viewingWhom);
          }
        });
      setStateReady(true);
    }
    prepare();
  }, []); // [] signals React to run the effect and clean it up only once (on mount and unmount)

  const myGoalsTabCols = [
    {
      text: "ID",
      dataField: "_id",
      editable: false,
      hidden: true,
    },
    {
      text: "Goal",
      dataField: "commitment",
      classes: "goal-column",
      formatter: (cell) => tableGoalFormatter(cell),
      validator: (newValue: string, row?: any, column?: any) => {
        if (newValue) {
          return true;
        }
        return {
          valid: false,
          message: "Please enter a goal",
        };
      },
      editable: true,
      headerStyle: {
        width: "60%",
        fontWeight: "normal",
      },
    },
    {
      text: "",
      dataField: "",
      classes: "delete-column",
      editable: false,
      formatter: (cell, row) => {
        return (
          <button
            className="delete-button"
            hidden={viewingIdx !== Object.keys(goals2Data).length - 1}
            onClick={() => {
              deleteGoal(row);
            }}
          >
            <svg
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.7"
                d="M16.875 3.625H13.6328L12.3047 1.4375C11.9922 0.929688 11.2891 0.5 10.7031 0.5H6.75781C6.17188 0.5 5.46875 0.929688 5.15625 1.4375L3.82812 3.625H0.625C0.273438 3.625 0 3.9375 0 4.25V4.875C0 5.22656 0.273438 5.5 0.625 5.5H1.25L2.07031 18.7422C2.10938 19.7188 2.96875 20.5 3.94531 20.5H13.5156C14.4922 20.5 15.3516 19.7188 15.3906 18.7422L16.25 5.5H16.875C17.1875 5.5 17.5 5.22656 17.5 4.875V4.25C17.5 3.9375 17.1875 3.625 16.875 3.625ZM6.75781 2.375H10.7031L11.4453 3.625H6.01562L6.75781 2.375ZM13.5156 18.625H3.94531L3.125 5.5H14.3359L13.5156 18.625Z"
                fill="#345371"
              />
            </svg>
          </button>
        );
      },
      headerStyle: {
        width: "10%",
      },
    },
    {
      text: "Status",
      dataField: "score",
      classes: "status-column",
      formatter: (cell) => tableStatusFormatter(cell),
      editor: {
        type: Type.SELECT,
        options: statusOptions.slice(0, -1),
      },
      headerStyle: {
        width: "30%",
        fontWeight: "normal",
      },
    },
  ];

  const rowEvents = {
    onMouseEnter: (e) => {
      let tgt = e.target;

      while (tgt.localName !== "tr" && tgt.parentElement !== null) {
        tgt = tgt.parentElement;
      }

      if (tgt) {
        tgt.classList.add("active-row");
        tgt.style.cursor = "pointer";
      }
    },
    onMouseLeave: (e) => {
      let tgt = e.target;

      while (tgt.localName !== "tr" && tgt.parentElement !== null) {
        tgt = tgt.parentElement;
      }

      if (tgt) {
        tgt.classList.remove("active-row");
        tgt.style.cursor = "auto";
      }
    },
  };

  async function addNextWeek(userId, date) {
    // TODO(jfechter): Can you do some research or consult Saraswati whether we
    // should 1) first update Mongo then refresh our state (addGoal, deleteGoal) (can we make state listen to Mongo?)
    // 2) update state, and then repeat logic to update Mongo (editGoal)
    // 3) update state, and update Mongo using state (can we make Mongo listen to state?)
    await realmApp.appCurrentUser?.functions.addGoals2Week(date, userId);
    fetchFromMongoAndSetState(); // Update state from Mongo
  }

  async function addGoal() {
    if (viewingIdx != Object.keys(goals2Data).length - 1) {
      alert(
        "This week has ended. You can only create new goals in the current week!"
      );
    }

    await realmApp.appCurrentUser?.functions.addNewGoal2(
      getGoalTimestampFromIdx(viewingIdx),
      viewingWhom
    );

    fetchFromMongoAndSetState(); // Update state from Mongo
  }

  async function copyGoalsToCurrentWeek(copy_idx) {
    if (viewingIdx != Object.keys(goals2Data).length - 1) {
      alert(
        "This week has ended. You can only create new goals in the current week!"
      );
    }

    if (copy_idx < 0 || copy_idx > Object.keys(goals2Data).length - 1) {
      console.log(
        `error: trying to copy index ${copy_idx} but goal length ` +
          `is ${Object.keys(goals2Data).length}`
      );
    }
    let commitments_to_copy = goals2Data[getGoalTimestampFromIdx(copy_idx)]
      .map((g) => {
        return g.commitment;
      })
      .filter((c) => {
        return c != "";
      });

    let result = await realmApp.appCurrentUser?.functions.addNewGoals2Bulk(
      getGoalTimestampFromIdx(viewingIdx),
      viewingWhom,
      commitments_to_copy
    );
    fetchFromMongoAndSetState(); // Update state from Mongo
  }

  async function copyGoalsFromLastWeek() {
    const lastWeekIdx = Object.keys(goals2Data).length - 2;
    if (lastWeekIdx < 0) {
      return;
    }
    // if (
    //   confirm(
    //     "You already have some identical goals this week. Do you still want to copy from last week?"
    //   )
    // ) {
    return copyGoalsToCurrentWeek(lastWeekIdx);
    // }
  }

  async function deleteGoal(row) {
    /* Delete from Mongo then update state */
    await realmApp.appCurrentUser?.functions.deleteGoal2(
      getGoalTimestampFromIdx(viewingIdx),
      row._id,
      viewingWhom
    );

    // Update state
    let newGoals2Data = {};
    for (const week in goals2Data) {
      newGoals2Data[week] = [];
      for (const goal of goals2Data[week]) {
        if (goal._id !== row._id) {
          newGoals2Data[week].push(goal);
        }
      }
    }
    setGoals2Data({});
    setGoals2Data(newGoals2Data);
  }

  async function editGoal(row, col, newVal) {
    // Update state
    const newGoals2Data = update(goals2Data, {
      [getGoalTimestampFromIdx(viewingIdx)]: {
        [editRowIdx]: { [col.dataField]: { $set: newVal } },
      },
    });
    if (newGoals2Data) {
      setGoals2Data(newGoals2Data);
    } else {
      setGoals2Data({});
    }

    // // Update MongoDB
    await realmApp.appCurrentUser?.functions.editGoals2ForUser(
      viewingWhom,
      col.dataField,
      getGoalTimestampFromIdx(viewingIdx),
      newVal,
      row._id
    );
  }

  return (
    <>
      <div className="page">
        <DashboardNav />
        <h2 className="alignleft"> Weekly Goals</h2>
        <Container>
          <Navbar className="goals-sub-nav">
            <Navbar.Brand>
              <button
                className={
                  viewingWhom && viewingWhom === myUserId
                    ? "goals-tab-selected"
                    : "goals-tab-inactive"
                }
                onClick={async () => {
                  url.searchParams.delete("view");
                  history.pushState(null, "", url.toString());
                  fetchFromMongoAndSetState();
                }}
              >
                My Goals
              </button>
            </Navbar.Brand>{" "}
            <Navbar.Brand>
              <button
                className={
                  stateReady && viewingWhom !== myUserId
                    ? "goals-tab-selected"
                    : "goals-tab-inactive"
                }
                onClick={async () => {
                  url.searchParams.set("view", "theirs");
                  history.pushState(null, "", url.toString());
                  fetchFromMongoAndSetState();
                }}
              >
                {menteeName}'s Goals
              </button>
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              <Navbar.Text>
                {/* TODO(tjann): not sure about edge cases yet */}
                {stateReady &&
                  viewingIdx >= 0 &&
                  "Week " +
                    (getCircleWeekNumber(getGoalTimestampFromIdx(viewingIdx))
                      ? `${getCircleWeekNumber(
                          getGoalTimestampFromIdx(viewingIdx)
                        )}: `
                      : "") +
                    "ending " +
                    moment(getGoalTimestampFromIdx(viewingIdx)).format(
                      "MMM Do"
                    )}
                {!stateReady && ""}
                <button
                  className="tight-button arrow-button"
                  disabled={viewingIdx <= 0}
                  onClick={() => {
                    setViewingIdx((viewingIdx) => viewingIdx - 1);
                  }}
                  style={{ marginLeft: "2.5rem", marginRight: "0.5rem" }}
                >
                  ❮
                </button>
                <button
                  className="tight-button arrow-button"
                  disabled={viewingIdx >= Object.keys(goals2Data).length - 1}
                  onClick={() => {
                    setViewingIdx((viewingIdx) => viewingIdx + 1);
                  }}
                >
                  ❯
                </button>
              </Navbar.Text>
            </Navbar.Collapse>
          </Navbar>
          <div className="page-content">
            <BootstrapTable
              hover
              bordered={false}
              rowEvents={rowEvents}
              classes="goals-table"
              headerClasses="goals-table-header"
              keyField="_id"
              alignItem={"center"}
              data={
                goals2Data &&
                viewingIdx >= 0 &&
                goals2Data[getGoalTimestampFromIdx(viewingIdx).toString()]
                  ? goals2Data[getGoalTimestampFromIdx(viewingIdx)]
                  : []
              }
              columns={myGoalsTabCols}
              cellEdit={cellEditFactory({
                mode: "click",
                blurToSave: true,
                onStartEdit: (row, column, rowIndex, columnIndex) => {
                  setEditRowIdx(rowIndex);
                },
                beforeSaveCell: (oldValue, newValue, row, column) => {},
                afterSaveCell: (oldValue, newValue, row, column) => {
                  editGoal(row, column, newValue);
                },
              })}
            />
            {!stateReady && <div>loading...</div>}

            {/* User does not have a mentor yet. */}
            {stateReady &&
              viewingWhom === myUserId &&
              realmApp.appCustomData?.mentor === null && (
                <div>
                  Please sit tight while we find you a{" "}
                  {getRelationTitle("mentor")}, who will guide you through goal
                  setting.
                </div>
              )}
            {/* User does not have a mentorship meeting set yet. */}
            {stateReady &&
              viewingWhom === myUserId &&
              realmApp.appCustomData?.mentor &&
              !nextMtgTime && (
                <div>
                  Once your {getRelationTitle("mentor")} sets up a schedule,
                  you'll be able to track goals here!
                </div>
              )}
            {/* User has no mentee. */}
            {stateReady && viewingWhom == null && (
              <div>
                You have not been assigned a {getRelationTitle("mentee")} yet.
              </div>
            )}
            {stateReady &&
              viewingWhom &&
              viewingWhom === menteeUserId &&
              !nextMtgTime && (
                <div>
                  Please{" "}
                  <Link to="/member/dashboard/team/">schedule a meeting</Link>{" "}
                  with your {getRelationTitle("mentee")} to talk about their
                  goals.
                </div>
              )}

            {/* User has no goals at all.  TODO?*/}

            {/* User is on current week but has not set any goals yet. */}
            {stateReady &&
              nextMtgTime &&
              viewingIdx === Object.keys(goals2Data).length - 1 &&
              goals2Data[getGoalTimestampFromIdx(viewingIdx)]?.length === 0 && (
                <div>
                  <p> </p>
                </div>
              )}
            {/* User is on current week and has some goals already. */}
            {stateReady &&
              nextMtgTime &&
              viewingIdx === Object.keys(goals2Data).length - 1 &&
              getGoalTimestampFromIdx(viewingIdx) > Date.now() && (
                <>
                  <Button className="deepen-blue-button" onClick={addGoal}>
                    New Goal
                  </Button>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {Object.keys(goals2Data).length > 1 && (
                    <Button
                      className="light-blue-button"
                      onClick={copyGoalsFromLastWeek}
                    >
                      Copy From Last Week
                    </Button>
                  )}
                </>
              )}
            {/* User is viewing latest recorded week, which ended in the past. */}
            {stateReady &&
              nextMtgTime &&
              viewingIdx >= 0 &&
              viewingIdx === Object.keys(goals2Data).length - 1 &&
              getGoalTimestampFromIdx(viewingIdx) <= Date.now() && (
                <Button
                  className="deepen-blue-button"
                  onClick={() => addNextWeek(viewingWhom, nextWeekEndTime)}
                >
                  Start new week
                </Button>
              )}
            {/* User is viewing latest recorded week, which ended in the past. */}
            {stateReady && nextMtgTime && viewingIdx === -1 && (
              <Button
                className="deepen-blue-button"
                onClick={() => addNextWeek(viewingWhom, nextWeekEndTime)}
              >
                Get started!
              </Button>
            )}
          </div>
        </Container>
        {stateReady && nextMtgTime && Object.keys(suggestions).length > 0 && (
          <>
            <h2 className="alignleft">Suggested Goals</h2>
            <Container>
              <Navbar className="goals-sub-nav">
                <Navbar.Brand>
                  <button
                    className={
                      !viewingOldSuggestions
                        ? "goals-tab-selected"
                        : "goals-tab-inactive"
                    }
                    onClick={async () => {
                      setViewingOldSuggestions(false);
                    }}
                  >
                    This Week
                  </button>
                </Navbar.Brand>
                <Navbar.Brand>
                  <button
                    className={
                      viewingOldSuggestions
                        ? "goals-tab-selected"
                        : "goals-tab-inactive"
                    }
                    onClick={async () => {
                      setViewingOldSuggestions(true);
                    }}
                  >
                    Previous Weeks
                  </button>
                </Navbar.Brand>
              </Navbar>
              <Suggestions
                suggestions={suggestions}
                isViewingHistory={viewingOldSuggestions}
                timezone={timezone}
              />
            </Container>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default ({ location }) => {
  return (
    <RealmAppProvider appId={APP_ID}>
      <OnboardingRedirector location={location}>
        <MyGoalsPage />
      </OnboardingRedirector>
    </RealmAppProvider>
  );
};
